<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { CsvRowStatus, InstrumentType } from '~/enums';
import type { ImportDtoType } from '~/instrument.schema';
import InvestmentForm from '@/views/investment/InvestmentForm.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { useI18n } from 'vue-i18n';
import SearchAccountForm from '@/views/account/SearchAccountForm.vue';
import Transactions from './Transactions.vue';
import type { Transaction } from './types';
import { fieldDesign } from '@/const';
import { useAppStore } from '@/stores/app';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import BulkEditForm from './BulkEditForm.vue';

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
    required: false
  },
  transactions: {
    type: Array as PropType<Transaction[]>,
    required: true
  },
  cashAccountId: {
    type: Number,
    required: false
  },
  cashAccount: {
    type: Object,
    required: false
  }
})

const isLoading = ref(false);
const portfolioStore = usePortfolioStore();
const router = useRouter();
const appStore = useAppStore();
const tab = ref('review');
const selectedItem = ref<any>(null);
const showCreateInvestment = ref(false);
const showCreateAccount = ref(false);
const showSplitModal = ref(false);
const isConfirmLeaveVisible = ref(false);
const showBulkEditModal = ref(false);
const nextRoute = ref<any>(null);

const split = ref<any>({
  count: 0,
  fee: 0,
  tax: 0
});

const reviewableTransactions = computed(() => {
  return props.transactions.filter((element: any) => element.status === CsvRowStatus.Review);
})

const porblematicTransactions = computed(() => {
  return props.transactions.filter((element: any) => element.status === CsvRowStatus.Error);
})

const importedTransactions = computed(() => {
  return props.transactions.filter((element: any) => element.status === CsvRowStatus.Imported);
})

const onInstrumentSelected = (item: Transaction) => {
  props.transactions.forEach((element: Transaction) => {
    const hasInstrumentCode = element.original.instrumentCode && element.original.instrumentCode === item.original.instrumentCode;
    const hasInstrumentName = element.original.instrumentName && element.original.instrumentName === item.original.instrumentName;
    const isDifferentInstrument = element.instrument.id !== item.instrument.id;
    const isCashOrLoan = element.original.instrumentType === InstrumentType.Cash || element.original.instrumentType === InstrumentType.Loan;
    if (isCashOrLoan) {
      return;
    }
    if ((hasInstrumentCode || hasInstrumentName) && isDifferentInstrument) {
      element.instrument = item.instrument;
    }
  });
}

const createInstrument = (item: Transaction) => {
  selectedItem.value = item;
  showCreateInvestment.value = true;
}

const onInvestmentCreated = (instrument: any) => {
  selectedItem.value.instrument_id = instrument.id;
  selectedItem.value.instrument = instrument;
  onInstrumentSelected(selectedItem.value);
  showCreateInvestment.value = false;
}

const onInvestmentSelected = (holding: any) => {
  selectedItem.value.instrument.id = holding.id || holding.uniqueId;
  selectedItem.value.instrument.name = holding.name;
  selectedItem.value.instrument.symbol = holding.symbol;
  selectedItem.value.instrument.type = holding.type;
  selectedItem.value.instrument.exchange = holding.exchange;
  selectedItem.value.instrument.currencyCode = holding.currencyCode;
  selectedItem.value.instrument.isin = holding.isin;
  selectedItem.value.instrument.logo = holding.logo;
  onInstrumentSelected(selectedItem.value);
  showCreateInvestment.value = false;
  showCreateAccount.value = false;
}

const selectedTransactions = computed(() => {
  return props.transactions.filter((element: any) => element.control.isSelected);
})

const onBulkEditConfirm = (item: any) => {
  selectedTransactions.value.forEach((element) => {
    if (item.instrument.id) {
      element.instrument = Object.assign(element.instrument, item.instrument);
    }
    if (item.transactionType) {
      element.transactionType = item.transactionType;
    }
  });
  showBulkEditModal.value = false;
}

const importSelected = async () => {
  const importable: Transaction[] = visibleTransactions.value
    .filter((e: any) => e.control.isSelected && e.control.isValid) as any as Transaction[];
  
  const transactions: ImportDtoType[] = importable
    .map((element) => {
      return {
        uuid: element.uuid,
        order: element.order,
        portfolioId: portfolioStore.activePortfolioId,
        transactionType: element.transactionType,
        referenceId: element.uniqueId,
        referenceIds: element.uniqueIds,
        dateTransactionAt: element.date,
        counterparty: element.counterparty,
        account: element.account,
        rawSymbol: element.original.instrumentCode,
        description: element.description,
        counterpartyName: element.counterpartyName,
        counterpartyAccount: element.counterpartyAccount,
        instrument: {
          id: element.instrument.id,
          symbol: element.instrument.symbol,
          exchange: element.instrument.exchange,
          currencyCode: element.instrument.currencyCode,
          type: element.instrument.type,
        },
        metadata: element.metadata,
      } as ImportDtoType;
    });

  try {
    isLoading.value = true;
    const portfolioId = portfolioStore.activePortfolioId
    const cashAccountId = props.cashAccountId ?? '';
    
    const chunkSize = 100;
    const results = [];
  
    for (let i = 0; i < transactions.length; i += chunkSize) {
      const chunk = transactions.slice(i, i + chunkSize);
      try {
        const result = await portfolioStore.importTransactions(portfolioId, cashAccountId, chunk);
        results.push(...result);
      } catch (e) {
        console.error(e);
      }
    }
    const resultMap = new Map(results.map((e: any) => [e.uuid, e]));
    let successful = 0;

    importable.forEach((element: any) => {
      const result = resultMap.get(element.uuid);
      if (result?.status === 'success') {
        element.error = undefined;
        element.status = CsvRowStatus.Imported;
        successful++;
      } else {
        element.error = result?.error;
      }
    });

    appStore.showNotice(t('csv_import.messages.imported_transactions', { a: successful, b: importable.length }));
  } finally {
    isLoading.value = false;
  }
}

const deleteTransaction = (item: Transaction) => {
  const referenceIds = item.uniqueIds || [item.uniqueId];
  portfolioStore.deleteUnconfirmedTransaction(portfolioStore.activePortfolioId, props.cashAccountId, referenceIds);
  item.status = CsvRowStatus.Ignore;
}

const deleteSelected = () => {
  const ids = visibleTransactions.value.filter(e => e.control.isSelected).map(e => {
    e.status = CsvRowStatus.Ignore;
    return e.uniqueIds || [e.uniqueId];
  }).flat();
  portfolioStore.deleteUnconfirmedTransaction(portfolioStore.activePortfolioId, props.cashAccountId, ids);
}

const splitTransaction = (item: Transaction) => {
  selectedItem.value = item;
  split.value = [{
    count: 0,
    fee: 0,
    tax: 0
  }];
  showSplitModal.value = true;
}

const confirmSplit = () => {
  const newTransaction = JSON.parse(JSON.stringify(selectedItem.value));
  newTransaction.account.quantity = parseFloat(split.value.count);
  newTransaction.account.netAmount = parseFloat(split.value.count) * parseFloat(newTransaction.account.price || 1);
  newTransaction.account.grossAmount = newTransaction.account.netAmount;
  newTransaction.account.fee = parseFloat(split.value.fee);
  newTransaction.account.tax = parseFloat(split.value.tax);
  newTransaction._account.netAmount = newTransaction.account.netAmount;
  newTransaction.uuid = Date.now().toString();

  selectedItem.value.account.quantity = parseFloat(selectedItem.value.account.quantity) - parseFloat(split.value.count);
  selectedItem.value.account.netAmount = parseFloat(selectedItem.value.account.netAmount) - newTransaction.account.netAmount;
  selectedItem.value.account.grossAmount = selectedItem.value.account.netAmount;
  selectedItem.value.account.fee = parseFloat(selectedItem.value.account.fee) - parseFloat(split.value.fee);
  selectedItem.value.account.tax = parseFloat(selectedItem.value.account.tax) - parseFloat(split.value.tax);
  selectedItem.value._account.netAmount = selectedItem.value.account.netAmount;

  const index = props.transactions.indexOf(selectedItem.value);
  props.transactions.splice(index + 1, 0, newTransaction);
  showSplitModal.value = false;
}

const markAsFixed = () => {
  visibleTransactions.value.filter(e => e.control.isSelected && e.control.isValid).forEach(e => {
    e.status = CsvRowStatus.Review;
  })
  if (visibleTransactions.value.length === 0) {
    tab.value = 'review';
  }
}

const visibleTransactions = computed(() => {
  if (tab.value === 'rejected') {
    return porblematicTransactions.value
  } else if (tab.value === 'review') {
    return reviewableTransactions.value
  } else {
    return importedTransactions.value
  }
})

const prefill = computed(() => {
  return {
    name: selectedItem.value?.instrument.name,
    symbol: selectedItem.value?.instrument.symbol,
    currencyCode: selectedItem.value?.instrument.currencyCode,
    isin: selectedItem.value?.instrument.isin,
    description: selectedItem.value?.original.description,
    counterpartyName: selectedItem.value?.counterpartyName,
    counterpartyAccount: selectedItem.value?.counterpartyAccount,
    suggestedInstrumentIds: selectedItem.value?.instrument?.suggestedIds,
  }
})

if (porblematicTransactions.value.length > 0) {
  tab.value = 'rejected';
} else if (reviewableTransactions.value.length > 0) {
  tab.value = 'review';
} else {
  tab.value = 'imported';
}

const onAction = (action: string) => {
  if (action === 'delete') {
    deleteSelected();
  } else if (action === 'import') {
    importSelected();
  } else if (action === 'fix') {
    markAsFixed();
  } else if (action === 'edit') {
    showBulkEditModal.value = true;
  }
}

const confirmLeave = () => {
  router.push(nextRoute.value);
}

onBeforeRouteLeave((to, from, next) => {
  if (isConfirmLeaveVisible.value && nextRoute.value) {
    isConfirmLeaveVisible.value = false;
    return next();
  }
  nextRoute.value = to;
  if (isLoading.value) {
    appStore.showNotice(t('csv_import.messages.import_in_progress'), 'warning');
    next(false);
  } else if (reviewableTransactions.value.length > 0) {
    isConfirmLeaveVisible.value = true;
    next(false);
  } else if (porblematicTransactions.value.length > 0) {
    isConfirmLeaveVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<template>
  <v-row>
    <v-col cols="12" lg="12">
      <DisplayCard color="panel-heading-bg">
        <v-tabs
          v-model="tab"
          bg-color="panel-heading-bg"
        >
          <v-tab value="rejected">{{ $t('csv_import.tabs.rejected') }}
            <v-badge color="error" :content="porblematicTransactions.length" inline />
          </v-tab>
          <v-tab value="review">{{ $t('csv_import.tabs.review') }}
            <v-badge color="info" :content="reviewableTransactions.length" inline />
          </v-tab>
          <v-tab value="imported">{{ $t('csv_import.tabs.imported') }}
            <v-badge color="success" :content="importedTransactions.length" inline />
          </v-tab>
        </v-tabs>
        
        <v-window v-model="tab">
          <v-window-item value="imported" eager>
            <div class="pa-6">
              <p class="text-body-1">
                {{ $t('csv_import.imported_description') }}
              </p>
            </div>
            <Transactions
              :transactions="importedTransactions"
              :editable="false"
            />
          </v-window-item>
          <v-window-item value="review" eager>
            <div class="pa-6">
              <p class="text-body-1">
                {{ $t('csv_import.review_description') }}
              </p>
            </div>
            <Transactions
              :transactions="reviewableTransactions"
              :cashAccount="props.cashAccount"
              flavour="import"
              :isLoading="isLoading"
              @action="onAction"
              @createInstrument="createInstrument"
              @delete="deleteTransaction"
              @split="splitTransaction"
            />
          </v-window-item>
          <v-window-item value="rejected" eager>
            <div class="pa-6">
              <p class="text-body-1" v-html="$t('csv_import.rejected_description')" />
            </div>
            <Transactions
              :transactions="porblematicTransactions"
              :cashAccount="props.cashAccount"
              flavour="fix"
              :isLoading="isLoading"
              @action="onAction"
              @createInstrument="createInstrument"
              @delete="deleteTransaction"
              @split="splitTransaction"
            />
          </v-window-item>
        </v-window>
      </DisplayCard>
    </v-col>
  </v-row>
  <v-dialog :max-width="900" v-model="showCreateInvestment" :scrollable="true">
    <InvestmentForm
      type="create"
      :variant="selectedItem ? 'search' : 'create'"
      @created="onInvestmentCreated"
      @selected="onInvestmentSelected"
      :prefill="prefill"
      :title="$t('csv_import.create_investment')"
    />
  </v-dialog>
  <v-dialog :max-width="900" v-model="showCreateAccount" :scrollable="true">
    <SearchAccountForm
      @created="onInvestmentCreated"
      @selected="onInvestmentSelected"
      :prefill="prefill"
      :title="$t('csv_import.choose_account')"
    />
  </v-dialog>
  <v-dialog :max-width="500" v-model="showSplitModal" scrollable>
    <DisplayCard :title="$t('csv_import.split_title')">
      <div class="pa-6">
        <p class="mb-10">
          {{ $t('csv_import.split_description') }}
        </p>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="split.count" :label="$t('label.quantity')" v-bind="fieldDesign" />
          </v-col>
          <v-col cols="12" v-if="selectedItem.account.fee">
            <v-text-field v-model="split.fee" :label="$t('label.fees')" v-bind="fieldDesign" />
          </v-col>
          <v-col cols="12" v-if="selectedItem.account.tax">
            <v-text-field v-model="split.tax" :label="$t('label.taxes')" v-bind="fieldDesign" />
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div class="pa-6 text-sm-right text-center">
        <v-btn @click="confirmSplit" color="primary">
          {{ $t('label.confirm') }}
        </v-btn>
      </div>
    </DisplayCard>
  </v-dialog>
  <Dialog
    v-model:model-visible="isConfirmLeaveVisible"
    :title="$t('csv_import.confirm_leave_title')"
    :confirm="{show: true, label: $t('label.confirm'), color: 'primary'}"
    @confirm="confirmLeave"
  >
    {{ $t('csv_import.confirm_leave_message') }}
  </Dialog>
  <v-dialog :max-width="900" v-model="showBulkEditModal" :scrollable="true">
    <BulkEditForm
      @createInstrument="createInstrument"
      @confirm="onBulkEditConfirm"
      :transactions="selectedTransactions"
      :cash-account="cashAccount"
    />
  </v-dialog>
</template>
