import { colors, currencyFormatter, darkerColors, lighterColors } from "@/const";
import { type Ref, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { TransactionType } from "~/enums";
import { i18n } from '@/plugins/i18n';
import dayjs from "dayjs";
import vuetify from "@/plugins/vuetify";
import { useTheme } from 'vuetify';

export function useFutureCashflowReport (report: Ref<any>) {
  const { t } = useI18n();

  const isChartCummulative = ref(false)

  const chartCategories = computed(() => {
    return report.value?.summary?.groupSteps ?? [];
  });

  const summaryItems = computed(() => {
    return Object.entries(report.value?.summary?.byType ?? {}).map(([key, value]) => {
      return {
        type: key,
        // @ts-ignore
        ...value
      }
    }).filter((item) => {
      return item.totalCount > 0
    }).sort((a, b) => {
      return b.base - a.base
    })
  })

  const chartData = computed(() => {
    return summaryItems.value.map((item) => {
      let sum = 0;
      return {
        name: t(`cashflowTransactionType.${item.type}.title`),
        data: chartCategories.value.map((category: string) => {
          const itemValue = item.grouped[category] ?? 0
          sum += itemValue
          const show = isChartCummulative.value ? sum : itemValue
          return item.grouped[category] || isChartCummulative.value ? Math.round(show * 100) / 100 : null
        })
      }
    })
  })

  return {
    chartData,
    chartCategories,
    summaryItems,
    isChartCummulative
  }
}

export function usePerformanceReport (reports: Ref<any>, opts: {
  summaryInterval: Ref<string>,
  showAverages: Ref<boolean>
}) {
  const { t } = useI18n();
  const theme = useTheme();

  const isChartCummulative = ref(false)

  const formatDateToCategory = (date: string, interval: string) => {
    if (interval === 'month') {
      return dayjs(date).format('YYYY-MM')
    } else if (interval === 'year') {
      return dayjs(date).format('YYYY')
    } else if (interval === 'quarter') {
      return dayjs(date).format('YYYY') + ' Q' + Math.ceil(dayjs(date).month() / 3)
    } else {
      return dayjs(date).format('YYYY-MM-DD')
    }
  }

  const mainReport = computed(() => {
    return reports.value[0] || {};
  });

  const chartCategories = computed(() => {
    const categories: Record<string, string> = {}

    mainReport.value.items?.forEach((item: any) => {
      const category = formatDateToCategory(item.date, reports.value[0].params.interval)
      if (!categories[category]) {
        categories[category] = category;
      }
    })

    return Object.keys(categories).sort();
  });

  const calculateAverage = (data: any[]) => {
    return data.reduce((acc: number, item: any) => acc + item.y, 0) / data.length;
  }

  const chartData = computed(() => {
    let lines = reports.value.map((report: any, i: number) => {
      return {
        type: report.params.aggregationType === 'cumulative' ? 'area' : 'column',
        name: report.title,
        color: colors[i],
        yAxis: 0,
        zIndex: i == 0 ? 9 : i,
        data: report.items.map((item: any) => ({
          category: formatDateToCategory(item.date, report.params.interval),
          x: chartCategories.value.indexOf(formatDateToCategory(item.date, report.params.interval)),
          y: item.totalReturn * 100,
          color: i >= 0 ? undefined : item.totalReturn < 0 ? vuetify.theme.current.value.colors.error : vuetify.theme.current.value.colors.success
        })).filter((item: any) => chartCategories.value.includes(item.category))
      }
    })


    lines = lines.reduce((acc: any, line: any, i: number) => {
      acc.push(line);
      if (line.type === 'column' && opts.showAverages.value) {
        const avg = calculateAverage(line.data);
        acc.push({
          name: 'avg',
          color: colors[i],
          type:'line',
          dashStyle: 'dash',
          yAxis: 0,
          lineWidth: 2,
          pointPlacement: 'on',
          enableMouseTracking: false,
          marker: {
            enabled: false
          },
          zIndex: 10,
          data: [[0, avg], {x: chartCategories.value.length - 1, y: avg, 
            dataLabels: {
              enabled: false
            }
          }]
        })
      }
      return acc;
    }, []);

    lines.push({
      name: `Networth`,
      type: 'area',
      dashStyle: 'dash',
      threshold: null,
      yAxis: 1,
      zIndex: 0,
      color: theme.current.value.dark ? lighterColors[0] : darkerColors[0],
      showInLegend: true,
      fillOpacity: 0.1,
      data: (reports.value[0]?.items || []).map((item: any) => ({
        y: item.value
      }))
    })

    return lines;
  })

  const groups = computed(() => {
    return mainReport.value.items[0]?.groupPerformance.map((group: any) => {
      return {
        name: group.name,
      }
    })
  })

  const extractGroupData = (groupName: string) => {
    const report = mainReport.value;
    if (!report.items) {
      return null;
    }
    const items = report.items.map((item: any) => {
      return {
        date: item.date,
        value: item.groupPerformance.find((group: any) => group.name === groupName)?.value || 0,
        totalReturn: item.groupPerformance.find((group: any) => group.name === groupName)?.totalReturn || 0,
      }
    })
    return {
      title: groupName,
      params: report.params,
      items
    }
  }

  return {
    chartData,
    chartCategories,
    isChartCummulative,
    groups,
    extractGroupData
  }
}

export function createTransactionTooltip(item: { type: TransactionType, count: number, quantity: number, averagePrice: number, currency: string }) {
  let topLine = i18n.global.t(`transactionType.${item.type}.title`);
  let bottomLine = i18n.global.t('chart.tooltip.info_line', {
    quantity: item.quantity,
    price: currencyFormatter(item.averagePrice, item.currency)
  });

  switch (item.type) {
    case TransactionType.Buy:
      topLine = i18n.global.t('chart.tooltip.transaction_type.buy', { count: item.count });
      break;
    case TransactionType.Sell:
      topLine = i18n.global.t('chart.tooltip.transaction_type.sell', { count: item.count });
      break;
    case TransactionType.Dividend:
      bottomLine = '%net';
      break;
    case TransactionType.Interest:
      bottomLine = '%net';
      break;
  }

  topLine = topLine.replace('#', item.count.toString());
  bottomLine = bottomLine
    .replace('#', item.quantity.toString())
    .replace('%net', currencyFormatter(item.quantity, item.currency))
    .replace('%', currencyFormatter(item.averagePrice, item.currency))
  
  return `
    <div class="pa-1 text-darkText text-body-2">
      <div class="">${topLine}</div>
      <div class="font-weight-bold text-narrow text-body-1">${bottomLine}</div>
    </div>
  `
}