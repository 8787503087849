<script setup lang="ts">
import { useInvestmentStore } from '@/stores/investmentStore';
import { watch, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { dateTimeFormatter, currencyFormatter, countFormatter, icons } from '@/const';
import StatsCard from '@/components/shared/StatsCard.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import QuantityCell from '@/components/table/QuantityCell.vue';
import PriceCell from '@/components/table/PriceCell.vue';
import DateCell from '@/components/table/DateCell.vue';
import PerformanceBar from '@/components/PerformanceBar.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { instrumentConfig } from '~/config';
import { type InstrumentType, TransactionType } from '~/enums';
import PerformanceFilters from '@/components/PerformanceFilters.vue';
import IncomeModule from './IncomeModule.vue';
import LoanModule from './LoanModule.vue';
import MoveToListedInstrumentForm from './MoveToListedInstrumentForm.vue';
import MoveToOtherPortfolioForm from './MoveToOtherPortfolioForm.vue';
import { useI18n } from 'vue-i18n';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import ValueChart from '@/components/ValueChart.vue';
import { useReportStore } from '@/stores/reportStore';
import NotesModule from './NotesModule.vue';
import { usePagination, usePerformanceFilter, useFilters } from '@/filter.helper';
import { onMounted } from 'vue';
import NoPricePolicy from '@/components/NoPricePolicy.vue';

const router = useRouter();
const investmentStore = useInvestmentStore();
const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();
const { t } = useI18n();

const showOpenAndClosedPositions = ref(true);
const showMonetaryValue = ref(true);
const chartType = ref('value');
const { summaryPeriod, summaryRange } = usePerformanceFilter()
const { page, perPage, perPageOptions } = usePagination()
const showMoveToListedInstrumentModal = ref(false);
const showMoveToOtherPortfolioModal = ref(false);
const transactions = computed(() => investmentStore.transactions);
const summary = computed(() => investmentStore.investmentSummary);
const investment = computed(() => investmentStore.investment);
const portfolio = computed(() => portfolioStore.activePortfolio);
const config = computed(() => instrumentConfig[investmentStore.investment.instrument?.type as InstrumentType] ?? {})
const isNetworthLoading = ref(true);
const selected = ref([]);
const deleteLoading = ref(false);

const netWorth = ref<any>([]);
const priceHistory = ref<any>([]);

const loadData = async () => {
  investmentStore.getInvestmentSummary(investment.value.id, summaryPeriod.value, summaryRange.value);
  isNetworthLoading.value = true;
  investmentStore.getInvestmentNetworthSummary(investment.value.id, { period: summaryPeriod.value, ...summaryRange.value }).then((data) => {
    netWorth.value = data;
    isNetworthLoading.value = false;
  })
  reportStore.getPriceReport(portfolio.value.id, investment.value.instrumentId, { period: summaryPeriod.value, ...summaryRange.value }).then((data) => {
    priceHistory.value = data;
  })
}

const loadTransactions = async () => {
  if (!investmentStore.investment || !investmentStore.investment.id) {
    return;
  }
  investmentStore.getTransactions(investmentStore.investment.id, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  }).then(() => {
    selected.value = [];
    updateRoute();
  });
}

watch(() => investmentStore.investment, (investment) => {
  if (!investment || !investment.id) {
    return;
  }
  isNetworthLoading.value = true;
  loadTransactions();
  loadData();
}, { immediate: true });


watch(() => [summaryPeriod, summaryRange], loadData, { deep: true });

const showManagePriceButton = computed(() => {
  return investment.value.instrument?.isPublic === false && portfolio?.value.canEdit
})
const showMoveToListedButton = computed(() => {
  return config.value?.canBeMigratedToPublic && portfolio?.value.canEdit
})

const headers = computed(() => [{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'dateTransactionAt',
}, {
  title: t('label.transaction_type_short'),
  align: 'start',
  sortable: false,
  key: 'transactionType',
}, {
  title: t('label.description_short'),
  align: 'start',
  sortable: false,
  key: 'description',
}, {
  title: t('label.quantity'),
  align: 'end',
  sortable: false,
  key: 'quantity'
}, {
  title: t('label.price_short'),
  align: 'end',
  sortable: false,
  key: 'price',
  hidden: !config?.value.hasPrice
}, {
  title: t('label.fees'),
  align: 'end',
  sortable: false,
  key: 'fee',
}, {
  title: t('label.exchange_rate'),
  align: 'end',
  sortable: false,
  key: 'exchangeRate',
  hidden: portfolio.value?.currencyCode === investment.value?.currencyCode
}, {
  title: t('label.total_value'),
  align: 'end',
  sortable: false,
  key: 'totalValue',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}].filter(({ hidden }) => !hidden))

const createTransaction = () => {
  router.push({
    name: 'investment-create-transaction',
    params: { investmentId: investmentStore.investment.id }
  })
}

const editTransaction = (transaction) => {
  router.push({
    name: 'investment-edit-transaction',
    params: { investmentId: investmentStore.investment.id, transactionId: transaction.id }
  })
}

const { sync, updateRoute } = useFilters([{
  key: 'P',
  ref: page,
  type: 'int',
}, {
  key: 'PS',
  ref: perPage,
  type: 'int',
}])

watch(() => [page.value, perPage.value], () => {
  loadTransactions();
})

const summaryItem = computed(() => {
  return summary.value?.groups?.[0]?.items?.[0]
})

const infoLines = computed(() => {
  const investment = investmentStore.investment;
  if (!investment || !investment.id) {
    return [];
  }
  const item = summaryItem.value?.consolidation?.[0] ?? summaryItem.value;
  return [
    {
      title: t('label.quantity'),
      subtitle: countFormatter(item?.quantity),
      visible: config.value?.showQuantityInValue,
      class: 'balance'
    },
    {
      title: t('label.value'),
      subtitle: currencyFormatter(item?.valueInOriginalCurrency, investment.instrument?.currencyCode),
      visible: config.value?.showQuantityInValue,
      class: 'balance'
    },
    {
      title: t('label.costbase'),
      subtitle: currencyFormatter(item?.costBase, investment.instrument?.currencyCode),
      class: 'balance'
    },
    {
      title: t('label.costbasePerShare'),
      subtitle: currencyFormatter(item?.costBase / item?.quantity, investment.instrument?.currencyCode),
      visible: config.value?.showQuantityInValue,
      class: 'balance'
    },
    { title: t('label.investment_name'), subtitle: investment.instrument?.name },
    { title: t('label.investment_code_short'), subtitle: investment.instrument?.symbol },
    { title: t('label.currency'), subtitle: investment.instrument?.currencyCode },
    { title: t('label.portfolio'), subtitle: investment.portfolio?.name },
  ].filter(({ visible }) => visible !== false)
})

const chartOptions = computed(() => {
  return {
    values: chartType.value === 'price' ? priceHistory.value : netWorth.value,
    annotations: netWorth.value?.map(val => {
      const dates = Object.keys(val.info)
      return dates.map(date => {
        const items = Object.keys(val.info[date]);
        let i = 0;
        return items.filter(type => Object.keys(icons).includes(type)).map((type) => {
          return {
            x: new Date(date).getTime(),
            distance: i++ * 2,
            icon: icons[type].icon,
            color: icons[type].color,
            colorHex: icons[type].colorHex,
            type,
            data: val.info[date][type]
          }
        })
      }).flat()
    }).flat(),
    isLoading: isNetworthLoading.value,
    currencyCode: chartType.value === 'price' ? investment.value?.instrument?.currencyCode : portfolio.value?.currencyCode,
    round: false,
    label: chartType.value === 'price' ? t('label.price_short') : t('label.networth')
  }
})

const deleteSelected = async () => {
  if (!selected.value.length) {
    return;
  }
  deleteLoading.value = true;
  for(const id of selected.value) {
    await investmentStore.deleteTransaction(investment.value.id, id);
  }
  selected.value = [];
  await loadTransactions();
  await loadData();
  deleteLoading.value = false;
};

onMounted(() => {
  sync();
})
</script>

<template>
  <PageLayout :heading="investment?.instrument?.name">
    <template v-slot:actions>
      <PerformanceFilters
        v-model="summaryPeriod"
        v-model:range="summaryRange"
        v-model:showMonetaryValue="showMonetaryValue"
        v-model:showOpenAndClosedPositions="showOpenAndClosedPositions"
        v-model:chart-type-value="chartType"
        :is-chart-type-enabled="false"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="12" md="4" class="d-flex align-stretch">
        <StatsCard
          :config="config"
          :summary="summary"
          :subtitle="`${t('investment_page.value_on')} ${dateTimeFormatter(summary?.groups?.[0]?.items?.[0]?.endDate)}`"
          :loading="!summary?.portfolioId"
          color="primary-lighten-1"
        />
      </v-col>
      <v-col cols="12" sm="12" md="8" class="d-flex align-stretch">
        <PerformanceBar
          :loading="!summary?.portfolioId"
          :currencyCode="summary?.baseCurrencyCode"
          :performance="summary?.[!showOpenAndClosedPositions ? 'openPositions' : 'openAndClosedPositions']"
          color="primary-lighten-4"
        />
      </v-col>
      <v-col cols="12" v-if="chartOptions.values?.length">
        <ValueChart v-bind="chartOptions" />
      </v-col>
      <v-col cols="12" lg="9">
        <v-row>
          <v-col cols="12">
            <DisplayCard :title="$t('investment_page.transactions_title')" color="panel-heading-bg">
              <template v-slot:actions>
                <v-btn v-if="portfolio?.canEdit" @click="createTransaction" variant="flat" color="action" :size="$vuetify.display.xs ? 'small' : 'default'" prepend-icon="mdi-plus">
                  {{ t('investment_page.transactions_create') }}
                </v-btn>
              </template>
              <v-data-table-server
                class="mb-4"
                density="compact"
                v-model:items-per-page="perPage"
                v-model:page="page"
                v-model="selected"
                :headers="headers"
                :items-length="transactions.value.totalItems ?? 0"
                :items="transactions.value.items"
                :loading="!!transactions.loading"
                item-value="id"
                :items-per-page-options="perPageOptions"
                :show-select="portfolio?.canEdit"
              >
                <template v-slot:footer.prepend>
                  <v-btn 
                    v-if="selected.length" 
                    class="ml-4" 
                    color="error"
                    variant="tonal"
                    size="small"
                    :loading="deleteLoading"
                    @click="deleteSelected">
                    {{ t('label.delete') }}
                  </v-btn>
                  <v-spacer />
                </template>
                <template v-slot:item.dateTransactionAt="{ value }">
                  <DateCell :value="value" />
                </template>
                <template v-slot:item.transactionType="{ value, item }">
                  <TransactionTypeCell :value="value" :instrument-type="investment.instrument?.type" :transaction="item" />
                </template>
                <template v-slot:item.quantity="{ value, item }">
                  <span v-if="item.transactionType === TransactionType.Expenses"></span>
                  <PriceCell v-else-if="config.quantityIsCurrency" :value="value" :currency-code="item.currencyCode" />
                  <QuantityCell v-else :value="value" :transaction-type="item.transactionType" />
                </template>
                <template v-slot:item.price="{ value, item }">
                  <span v-if="item.transactionType === TransactionType.Expenses"></span>
                  <PriceCell v-else :value="value" :currency-code="item.currencyCode"  :transaction-type="item.transactionType" />
                </template>
                <template v-slot:item.fee="{ value, item }">
                  <PriceCell :value="-value" :currency-code="item.feeCurrencyCode" :transaction-type="item.transactionType" />
                </template>
                <template v-slot:item.totalValue="{ value, item }">
                  <PriceCell :value="value" :currency-code="item.baseCurrencyCode" :transaction-type="item.transactionType" :maximum-fraction-digits="2" adjust-sign isTotalValue />
                </template>
                <template v-slot:item.exchangeRate="{ value }">
                  {{ value }}
                </template>
                <template v-slot:item.description="{ value }">
                  {{ value }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon @click="editTransaction(item)" size="small" class="me-2" v-if="portfolio?.canEdit">
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  {{ t('label.no_data') }}
                </template>
              </v-data-table-server>
            </DisplayCard>
          </v-col>
          <v-col cols="12">
            <IncomeModule />
          </v-col>
          <v-col cols="12" v-if="config?.allowRelatedLoan">
            <LoanModule />
          </v-col>
          <v-col cols="12">
            <NotesModule :holding="investment" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <DisplayCard :title="t('investment_page.investment_info_title')">
          <v-list v-if="config?.hasPrice">
            <v-list-item>
              <v-list-item-subtitle class="text-subtitle-1">
                {{ t('label.current_price') }}
              </v-list-item-subtitle>
              <v-list-item-title v-if="summaryItem">
                <span v-if="summaryItem.price !== null" class="balance">
                  {{ currencyFormatter(summaryItem.price, summaryItem.currencyCode, 5) }}
                </span>
                <div v-else>
                  <NoPricePolicy />
                </div>
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-2" v-if="summaryItem">
                {{ t('investment_page.updated_at') }} {{ dateTimeFormatter(summaryItem.priceDate) }}
              </v-list-item-subtitle>
              <v-btn
                v-if="showManagePriceButton"
                :to="{ name: 'investment-manage-price', params: { investmentId: investment.id} }"
                class="mt-2"
                block
                variant="tonal"
                prepend-icon="mdi-pencil"
                size="small"
                color="light-blue">
                {{ t('label.manage_price') }}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pa-0 mt-n2" lines="one">
            <v-list-item
              v-for="(info, i) in infoLines"
              :key="i"
              :title="info.title"
              :subtitle="info.subtitle"
              :class="info.class"
            />
          </v-list>
          <div class="pa-4 d-flex flex-column" style="gap: 10px;">
            <v-btn
              :to="{name: 'investment-settings', params: { investmentId: investment.id } }"
              color="primary"
              rounded="sm"
              size="small"
              variant="flat"
              prepend-icon="mdi-cog"
              v-if="portfolio?.canEdit"
            >
              {{ $t('investment_page.settings') }}
            </v-btn>
            <v-btn
              v-if="showMoveToListedButton"
              block
              size="small"
              color="primary"
              variant="tonal"
              prepend-icon="mdi-clipboard-text-outline"
              @click="showMoveToListedInstrumentModal = true"
            >
              {{ $t('investment_page.move_to_listed_instrument') }}
            </v-btn>
          </div>
        </DisplayCard>
      </v-col>
    </v-row>
  </PageLayout>
  <v-dialog :max-width="900" v-model="showMoveToListedInstrumentModal" :scrollable="true">
    <MoveToListedInstrumentForm @close="showMoveToListedInstrumentModal = false" />
  </v-dialog>
  <v-dialog :max-width="900" v-model="showMoveToOtherPortfolioModal" :scrollable="true">
    <MoveToOtherPortfolioForm @close="showMoveToOtherPortfolioModal = false" />
  </v-dialog>
</template>
