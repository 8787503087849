<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChartType } from '~/enums';

const props = defineProps({
  modelValue: {
    default: ''
  }
});

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const grapTypeOptions = computed(() => {
  return [{
    title: t('filter.chart_type.total.title'),
    icon: 'mdi-chart-line',
    description: t('filter.chart_type.total.description'),
    value: ChartType.Total,
  }, {
    title: t('filter.chart_type.stacked.title'),
    icon: 'mdi-chart-areaspline',
    description: t('filter.chart_type.stacked.description'),
    value: ChartType.Stacked,
  }, {
    title: t('filter.chart_type.costbase-total.title'),
    icon: 'mdi-chart-line',
    description: t('filter.chart_type.costbase-total.description'),
    value: ChartType.CostbaseTotal
  }, {
    title: t('filter.chart_type.costbase-stacked.title'),
    icon: 'mdi-chart-areaspline',
    description: t('filter.chart_type.costbase-stacked.description'),
    value: ChartType.CostbaseStacked
  }, {
    title: t('filter.chart_type.growth.title'),
    icon: 'mdi-chart-bar-stacked',
    description: t('filter.chart_type.growth.description'),
    value: ChartType.Growth
  }]
})

const graphType = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});
</script>

<template>
   <v-select
    :label="$t('label.chart_type')"
    v-model="graphType"
    :items="grapTypeOptions"
    hide-details
    class="text-left"
    variant="outlined"
    style="min-width: 200px;"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="item.raw.title"
        :subtitle="item.raw.description"
      >
        <template v-slot:prepend>
          <v-icon size="36" :icon="item.raw.icon" />
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>