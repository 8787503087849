<script setup lang="ts">
import ValueWithArrow from '@/components/shared/ValueWithArrow.vue';
import { currencyFormatter } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useReportStore } from '@/stores/reportStore';
import { watch, type Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ValueChart from '@/components/ValueChart.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import { usePerformanceFilter } from '@/filter.helper';
import HideBalancesButton from '@/components/HideBalancesButton.vue';
import AssetAllocation from './AssetAllocation.vue';
import Performance from './Performance.vue';
import FutureIncome from './FutureIncome.vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/authStore';

const { t } = useI18n();
const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();
const router = useRouter();
const route = useRoute();

const drawer = ref(false);
const isLoading = ref(true);
const isNetworthLoading = ref(true);
const assetAllocationChart = ref<typeof AssetAllocation | null>(null);

const summary = ref<any>({});
const netWorth = ref<any>([]);
const valueIs = ref('value');
const authStore = useAuthStore();

const { summaryPeriod, summaryRange, summaryGroupBy, summaryChartType } = usePerformanceFilter()

watch(() => route.params.portfolioId, async newId => {
  if (newId) {
    portfolioStore.select(newId as any as number);
  }
}, { immediate:true });

watch(() => portfolioStore.selectedPortfolio, async portfolio => {
  if (portfolio?.hasAccounts === false) {
    router.push({ name: 'onboarding', params: { portfolioId: portfolioStore.selectedPortfolioId } });
  }
}, { immediate:true });

async function loadSummary() {
  if (!portfolioStore.selectedPortfolioId) {
    return
  }
  isLoading.value = true;
  isNetworthLoading.value = true;
  try {
    portfolioStore.getSummary(portfolioStore.selectedPortfolioId, summaryPeriod.value, summaryRange.value, summaryGroupBy.value)
      .then((data) => {
        summary.value = data;
      })
      .finally(() => {
        isLoading.value = false;
      });
    reportStore.getNetworthReport(portfolioStore.selectedPortfolioId, {
      period: summaryPeriod.value,
      ...summaryRange.value,
      groupBy: summaryGroupBy.value,
    }).then((data) => {
      netWorth.value = data;
    }).finally(() => {
      isNetworthLoading.value = false;
    });
  } finally {
  }
}

watch(() => portfolioStore.selectedPortfolioId, async () => {
  await loadSummary();
}, { immediate: true });
watch(() => [summaryPeriod, summaryRange, summaryGroupBy], loadSummary, { deep: true });

const hasPortfolios = computed(() => {
  return portfolioStore.portfolios.value?.length > 0
})

const isPortfoliosLoading = computed(() => {
  return portfolioStore.portfolios.loading
})

const cards: Ref<Record<string, any>> = ref({
  assetAllocationChart: {
    cols: authStore.getUserPreference(`dashboard.assetAllocationChart.cols`, 6),
    order: authStore.getUserPreference(`dashboard.assetAllocationChart.order`, 0),
    key: 'assetAllocationChart',
  },
  performanceChart: {
    cols: authStore.getUserPreference(`dashboard.performanceChart.cols`, 6),
    order: authStore.getUserPreference(`dashboard.performanceChart.order`, 1),
    key: 'performanceChart',
  },
  futureIncome: {
    cols: authStore.getUserPreference(`dashboard.futureIncome.cols`, 12),
    order: authStore.getUserPreference(`dashboard.futureIncome.order`, 2),
    key: 'futureIncome',
  }
})

const order = (card: any, direction: number) => {
  const isUp = direction === -1;
  const sortedList = Object.values(cards.value).sort((a, b) => a.order - b.order);
  const cardIndex = sortedList.findIndex(c => c === card);
  const next = sortedList[cardIndex + 1];
  const previous = sortedList[cardIndex - 1];

  if (isUp && cardIndex === 0) {
    return
  } else if (!isUp && cardIndex === sortedList.length - 1) {
    return
  } else if (isUp) {
    card.order = cardIndex - 1;
    previous.order = cardIndex;
  } else {
    card.order = cardIndex + 1;
    next.order = cardIndex;
  }
}

const reportCards = computed(() => {
  return [
    {
      component: AssetAllocation,
      refName: 'assetAllocationChart',
      ref: assetAllocationChart,
      cols: cards.value.assetAllocationChart.cols,
      order: cards.value.assetAllocationChart.order,
      props: {
        report: summary.value,
        isLoading: isNetworthLoading.value,
        title: t('label.asset_allocation'),
      }
    },
    {
      component: Performance,
      refName: 'performanceChart',
      cols: cards.value.performanceChart.cols,
      order: cards.value.performanceChart.order,
      props: {
        report: summary.value,
        isLoading: isLoading.value,
      }
    },
    {
      component: FutureIncome,
      refName: 'futureIncome',
      cols: cards.value.futureIncome.cols,
      order: cards.value.futureIncome.order,
      props: {
        isLoading: isLoading.value,
      }
    }
  ].sort((a, b) => a.order - b.order)
})

watch(cards, (value) => {
  Object.entries(value).forEach(([key, card]) => {
    authStore.setUserPreference(`dashboard.${key}.cols`, card.cols);
    authStore.setUserPreference(`dashboard.${key}.order`, card.order);
  });
}, { deep: true });
</script>

<template>
  <template v-if="hasPortfolios">
    <v-row class="mt-n5">
      <v-col cols="12" v-if="summary.isPartial">
        <PartialReportAlert />
      </v-col>
      <v-col cols="12" md="12">
        <div class="d-flex flex-row flex-md-row align-center justify-space-between">
          <div>
            <h1 style="font-size: 2.5em;letter-spacing:-0.03em;" class="font-weight-black text-darkprimary d-flex align-center">
              <span v-if="!isLoading" class="balance-lg">{{ currencyFormatter(summary?.value, summary?.currencyCode) }}</span>
              <v-progress-circular v-else size="24" indeterminate color="primary" class="mr-2" />
              <HideBalancesButton />
            </h1>
            <div class="d-flex ga-1 text-darkprimary text-body-1 mt-n1 align-center">
              <ValueWithArrow :value="summary?.openAndClosedPositions?.totalReturn ?? 0" :arrow="true" :color-text="false" />
              <HelpIcon color="darkprimary" :help-text="$t('help.performance')" />
              <span class="balance">({{ currencyFormatter(summary?.openAndClosedPositions?.totalGain, summary?.currencyCode) }})</span>
            </div>
          </div>
          <div>
            <v-btn
              icon="mdi-cog"
              @click.stop="drawer = !drawer"
              class="mt-n4"
              v-if="$vuetify.display.smAndDown"
            ></v-btn>
            <v-navigation-drawer
              location="right"
              temporary
              v-model="drawer"
              v-if="$vuetify.display.smAndDown"
            >
              <div class="pa-4">
                <PerformanceFilters
                  v-model="summaryPeriod"
                  v-model:groupByValue="summaryGroupBy"
                  v-model:range="summaryRange"
                  v-model:chartTypeValue="summaryChartType"
                  :is-summary="true"
                  :show-chart-type="true"
                  narrow
                />
                <v-btn
                  @click="drawer = false"
                  class="mt-4"
                  color="primary"
                  block
                >{{ $t('label.close') }}</v-btn>
              </div>
            </v-navigation-drawer>
            <PerformanceFilters
              v-else
              v-model="summaryPeriod"
              v-model:groupByValue="summaryGroupBy"
              v-model:range="summaryRange"
              v-model:chartTypeValue="summaryChartType"
              :is-summary="true"
              :show-chart-type="true"
            />
          </div>
        </div>
        <ValueChart
          :values="netWorth"
          :is-loading="isNetworthLoading"
          :currency-code="summary?.baseCurrencyCode"
          :value-key="valueIs"
          :chart-type="summaryChartType"
          :portfolio="portfolioStore.selectedPortfolio"
          :round="false"
        />
        <div class="mt-5"></div>
      </v-col>
      <v-col v-for="card in reportCards" :cols="12" :md="card.cols" class="d-flex align-stretch">
        <component
          :is="card.component"
          :ref="card.refName"
          v-bind="card.props"
        >
          <template #configuration>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn class="pa-0 ml-n4 mr-n5" icon="mdi-dots-vertical" variant="plain" v-bind="props" :ripple="false" slim></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <ButtonToggle
                    v-model="cards[card.refName].cols"
                    mode="icon"
                    density="compact"
                    :options="[{
                      title: 'mdi-size-m',
                      value: 6
                    }, {
                      title: 'mdi-size-xl',
                      value: 12
                    }]"
                    no-columns
                  />
                </v-list-item>
                <v-list-item>
                  <div class="d-flex ga-2">
                    <v-btn icon="mdi-menu-up-outline" tile variant="flat" @click="order(cards[card.refName], -1)"></v-btn>
                    <v-btn icon="mdi-menu-down-outline" tile variant="flat" @click="order(cards[card.refName], +1)"></v-btn>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </component>
      </v-col>
  </v-row>
</template>
  <div v-else-if="isPortfoliosLoading">
    <v-row>
      <v-col cols="12">
        <v-progress-linear indeterminate color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>
