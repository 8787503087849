import dayjs from "dayjs";
import { computed, ref } from "vue";
import { Currency, TransactionType } from "~/enums";
import { AccountsConfig } from "~/accounts.config";
import { i18n } from "./plugins/i18n";
import isNil from "lodash/isNil";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

export const dayjsUtc = dayjs;

const { t, te } = i18n.global

export type FieldDesign = {
  color: string;
  variant: string;
  hideDetails: boolean | 'auto';
  persistentHint: boolean;
  density?: 'compact';
}

export type DtoLoading = { loading: boolean }
export type DtoError = { error: string }
export type RemoteObject = DtoLoading | DtoError | {}

export const colors = ['#6930C3', '#27aeef', '#ef9b20', '#87bc45', '#00bfa0', '#CF30C3', '#FFB0C7', '#FFB8AA', '#FFC88C', '#FFDF75', '#F9F871', '#b3d4ff', '#e60049'];
export const chartColors = ["#FAAC34","#ED6268","#D24C84","#5D439C","#23438A","#FFDB44","#F5804A","#A4479F","#2E658B","#171782","#FBC26A","#F28C91","#E085AB","#8870C2","#5C82D6","#FFEB99","#F9AA86","#D194CD","#408BBF","#2626D9","#F49606","#EA434B","#BC2F6A","#402E6B","#182F62","#FACA00","#F3601B","#81377C","#214863","#0F0F57"]
export const darkerColors = ['#4A2289'];
export const lighterColors = ['#966ED5'];

export const currencyCodes = ref(Object.values(Currency));
export const currencyCodesMap: Record<Currency, { code: string, name: string, flag: string }> = {
  [Currency.Euro]: { code: 'EUR', name: 'Euro', flag: '🇪🇺' },
  [Currency.US_Dollar]: { code: 'USD', name: 'US Dollar', flag: '🇺🇸' },
  [Currency.Australian_Dollar]: { code: 'AUD', name: 'Australian Dollar', flag: '🇦🇺' },
  [Currency.Canada_Dollar]: { code: 'CAD', name: 'Canada Dollar', flag: '🇨🇦' },
  [Currency.Danish_Krone]: { code: 'DKK', name: 'Danish Krone', flag: '🇩🇰' },
  [Currency.British_Pound]: { code: 'GBP', name: 'British Pound', flag: '🇬🇧' },
  [Currency.British_Pence]: { code: 'GBX', name: 'British Pence', flag: '🇬🇧' },
  [Currency.Honk_Kong_Dollar]: { code: 'HKD', name: 'Hong Kong Dollar', flag: '🇭🇰' },
  [Currency.Japanese_Yen]: { code: 'JPY', name: 'Japanese Yen', flag: '🇯🇵' },
  [Currency.Norwegian_Krone]: { code: 'NOK', name: 'Norwegian Krone', flag: '🇳🇴' },
  [Currency.Poland_Zloty]: { code: 'PLN', name: 'Poland Zloty', flag: '🇵🇱' },
  [Currency.Swedish_Krona]: { code: 'SEK', name: 'Swedish Krona', flag: '🇸🇪' },
  [Currency.Signapore_Dollar]: { code: 'SGD', name: 'Singapore Dollar', flag: '🇸🇬' },
  [Currency.Swiss_Franc]: { code: 'CHF', name: 'Swiss Franc', flag: '🇨🇭' },
}

export const fieldDesign = ref<FieldDesign>({
  color: 'primary',
  variant: 'outlined',
  hideDetails: 'auto',
  persistentHint: true
});

export const fieldDesignFilter = ref<FieldDesign>({
  color: 'primary',
  variant: 'outlined',
  hideDetails: 'auto',
  persistentHint: true,
  density: 'compact',
  class: 'mt-0  '
});

const formatters: Record<string, Intl.NumberFormat | Intl.DateTimeFormat> = {}

const formatHidden = (value: string) => {
  return value.replace(/[\d,\s]+/g, '***** ');
}

export const currencyFormatter = (value: number | undefined, currency: string, max = 2, min = 2, signDisplay = 'negative') => {
  if (value === undefined || value === null) {
    return '-';
  }
  const key = `${currency}-${max}-${min}-${signDisplay}`
  if (!currency) {
    return countFormatter(value);
  }
  if (!formatters[key]) {
    try {
      formatters[key] = new Intl.NumberFormat('et-EE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: min,
        maximumFractionDigits: max,
        signDisplay
      })
    } catch (e) {
      return countFormatter(value);
    }
  }

  const formatted = formatters[key].format(value);

  return formatted;
}

export const exchangeRateFormatter = (value: number, currency: string) => {
  if (!currency) {
    return countFormatter(value);
  }
  if (!formatters[currency]) {
    try {
      formatters[currency] = new Intl.NumberFormat('et-EE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 8
      })
    } catch (e) {
      return countFormatter(value);
    }
  }
  return formatters[currency].format(value)
}

export const percentFormatter = (value: number) => {
  if (!formatters['percent']) {
    formatters['percent'] = new Intl.NumberFormat('et-EE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return formatters['percent'].format(value)
}

export const countFormatter = (value: number | undefined) => {
  if (value === undefined || value === null) {
    return '-';
  }
  if (!formatters['count']) {
    formatters['count'] = new Intl.NumberFormat('et-EE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 5
    })
  }
  const formatted = formatters['count'].format(value)

  return formatted;
}

export const dateTimeFormatter = (value: string) => {
  if (!value) {
    return ''
  }
  const date = dayjs(value)
  if (!date.isValid()) {
    return '-'
  }

  if (!formatters['dateTime']) {
    formatters['dateTime'] = new Intl.DateTimeFormat('et-EE', {
      dateStyle: 'medium'
    })
  }
  return formatters['dateTime'].format(date.toDate())
}

export const dateFormatter = (value: string) => {
  if (!value) {
    return ''
  }
  const date = dayjs(value)
  if (!date.isValid()) {
    return '-'
  }

  return date.format('DD.MM.YYYY')
}

export const signNumberBasedOnTransactionType = (transactionType: TransactionType, value: number) => {
  if ([TransactionType.Buy, TransactionType.Writeoff, TransactionType.Fee, TransactionType.Tax, TransactionType.Expenses, TransactionType.InterestPaid].includes(transactionType)) {
    return -value;
  }
  return value;
}

export const formatNumberBasedOnTransactionType = (transactionType: TransactionType, value: number, colorPositive = false) => {
  if (signNumberBasedOnTransactionType(transactionType, value) < 0) {
    return {
      class: {
        'text-error': true
      }
    }
  }

  return {
    class: {
      'text-success': colorPositive && transactionType !== TransactionType.Opening
    }
  }
}

export const rules = ref({
  required: (value: string) => (!isNil(value) && value !== '')  || t('validation.required'),
  amount: (value: string) => {
    if (!value) {
      return true;
    }
    return !isNaN(parseFloat(value)) || 'Must be a number';
  },
  notNegative: (value: string) => {
    if (!value) {
      return true;
    }
    return parseFloat(value) >= 0 || 'Must be a positive number';
  },
  email: (value: string) => {
    if (!value) {
      return true;
    }
    return /.+@.+\..+/.test(value) || t('validation.email');
  },
  password: (value: string) => {
    if (!value) {
      return true;
    }
    return value.length >= 8 || t('validation.password');
  },
  terms: (value: boolean) => {
    return value || t('validation.terms');
  },
  negative: (value: string) => {
    if (!value) {
      return true;
    }
    return parseFloat(value) < 0 || 'Must be a negative number';
  },
  date: (value: string) => {
    if (!value) {
      return true;
    }
    return dayjs(value, ['DD.MM.YYYY', 'YYYY-MM-DD'], true).isValid() || 'Invalid date';
  },
  min: (min: number) => (value: string) => {
    if (!value) {
      return true;
    }
    return parseFloat(value) >= min || `Must be at least ${min}`;
  },
  max: (max: number) => (value: string) => {
    if (!value) {
      return true;
    }
    return parseFloat(value) <= max || `Must be at most ${max}`;
  },
  validTransaction: (allowedTypes: TransactionType[]) => (value: string) => {
    if (!value) {
      return true;
    }
    return allowedTypes.includes(value as TransactionType) || 'Invalid transaction type';
  }
});

export const numericInputConfig = {
  numeral: true,
  numeralDecimalScale: 10,
  numeralDecimalMark: '.',
  numeralThousandsGroupStyle: 'none'
}

export const nr = (value: any) => {
  return parseFloat(value || '0') || 0;
}

export const accountProviders = computed(() => {
  return Object.values(AccountsConfig)
    .map((provider) => {
      provider.title = te(`providers.${provider.key}`) ? t(`providers.${provider.key}`) : provider.title
      return provider
    })
})

export const icons = {
  [TransactionType.Buy]: {
    icon: 'mdi-arrow-up-circle',
    color: 'success',
    colorHex: '#4CAF50'
  },
  [TransactionType.Sell]: {
    icon: 'mdi-arrow-down-circle',
    color: 'error',
    colorHex: '#F44336'
  },
  [TransactionType.Dividend]: {
    icon: 'mdi-alpha-d-circle',
    color: 'primary'
  },
  [TransactionType.Interest]: {
    icon: 'mdi-alpha-i-circle',
    color: 'primary'
  },
  [TransactionType.Opening]: {
    icon: 'mdi-alpha-a-circle',
    color: 'amber-darken-3'
  },
}
