<script setup lang="ts">
import AccountLink from '@/components/shared/AccountLink.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { computed, onMounted, ref } from 'vue';
import { HoldingType } from '~/enums';
import LinkInfo from './LinkInfo.vue';
import { watch } from 'vue';
import { useDisplay } from 'vuetify';

const portfolioStore = usePortfolioStore();
const accountColumn = ref(null);
const width = ref(0);
const showAll = ref(false);
const { smAndDown } = useDisplay()

const accounts = computed(() =>
  portfolioStore
    .portfolioAccounts.item
    .filter((account) => account.type === HoldingType.Cash)
    .filter((account) => showAll.value || !account.isArchived)
)

const loans = computed(() =>
  portfolioStore
    .portfolioAccounts.item
    .filter((account) => account.type === HoldingType.Loan)
    .filter((account) => showAll.value || !account.isArchived)
)

const isLoading = computed(() => portfolioStore.portfolioAccounts.isLoading);

const isLinkingEnabled = computed(() => {
  return portfolioStore.selectedPortfolio?.canEdit;
});

const hasAccounts = computed(() => {
  return portfolioStore.portfolioAccounts.item.length > 0;
});

const hasLoans = computed(() => {
  return loans.value.length > 0;
});

const hasHiddenAccounts = computed(() => {
  return portfolioStore.portfolioAccounts.item.some((account) => account.isArchived);
});

watch(() => accountColumn.value, (value) => {
  if (value?.offsetWidth) {
    setTimeout(() => {
      width.value = value?.offsetWidth;
    }, 100);
  }
})

onMounted(async () => {
  await portfolioStore.getAllAccounts(portfolioStore.selectedPortfolioId);
})
</script>

<template>
  <v-row>
    <v-col cols="12">
      <DisplayCard icon="mdi-bank-outline" :title="$t('account_page.accounts_title')" variant="text" color="panel-heading-bg">
        <template v-slot:actions v-if="!portfolioStore.selectedPortfolio?.isConsolidated">
          <v-btn :to="{name: 'add-cash-account'}" color="blue" prepend-icon="mdi-plus-circle-outline" v-if="portfolioStore.selectedPortfolio?.canEdit">
            {{ smAndDown ? $t('label.create') : $t('account_page.add_account') }}
          </v-btn>
        </template>
        <v-table class="radius-0">
          <thead>
            <tr class="bg-table-heading-bg">
              <th style="min-width:250px;" ref="accountColumn">
                {{ $t('label.account') }}
              </th>
              <th style="min-width:150px;">
                {{ $t('label.portfolio') }}
              </th>
              <th>
                {{ $t('label.currencies') }}
              </th>
              <th v-if="isLinkingEnabled" class="text-right"></th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-for="account in accounts" :key="account.id">
              <td>
                <AccountLink :account="account" class="text-h4" />
              </td>
              <td>
                {{ account.portfolio?.name }}
              </td>
              <td>
                <div class="d-flex flex-row ga-1">
                  <v-chip
                    v-for="child in account.childAccounts"
                    :to="`/${account.type === 'loan' ? 'loan' : 'account'}/${child.id}`"
                    :key="child.id"
                    :color="child.currencyCode === 'EUR' ? 'primary' : 'secondary'">{{ child.currencyCode }}</v-chip>
                </div>
              </td>
              <td class="text-right" v-if="isLinkingEnabled">
                <LinkInfo :account="account" />
              </td>
            </tr>
            <tr v-if="hasHiddenAccounts">
              <td colspan="4">
                <v-btn variant="plain" color="primary" @click="showAll = !showAll">{{ showAll ? $t('account_page.hide_archived') : $t('account_page.show_all') }}</v-btn>
              </td>
            </tr>
            <tr v-if="!hasAccounts">
              <td colspan="4">
                {{ $t('account_page.no_accounts') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="3" class="text-center">
                <v-progress-circular indeterminate color="primary" />
              </td>
            </tr>
          </tbody>
        </v-table>
      </DisplayCard>
    </v-col>

    <v-col class="mt-5">
      <DisplayCard icon="mdi-cash" :title="$t('account_page.loans_title')" color="primary-lighten-1">
        <template v-slot:actions v-if="!portfolioStore.selectedPortfolio?.isConsolidated">
          <v-btn :to="{name: 'add-loan-account'}" color="amber" prepend-icon="mdi-plus-circle-outline" v-if="portfolioStore.selectedPortfolio?.canEdit">
            {{ $t('account_page.add_new_loan') }}
          </v-btn>
        </template>
        <v-table class="radius-0">
          <thead>
            <tr class="bg-table-heading-bg">
              <th :style="`width:${width}px`">
                {{ $t('label.loan') }}
              </th>
              <th>
                {{ $t('label.portfolio') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-for="account in loans" :key="account.id">
              <td>
                <AccountLink :account="account" class="text-h4" />
              </td>
              <td>
                {{ account.portfolio?.name }}
              </td>
            </tr>
            <tr v-if="!hasLoans">
              <td colspan="2">
                {{ $t('account_page.no_loans') }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </DisplayCard>
    </v-col>
  </v-row>
</template>


<style scoped lang="scss">
:deep(tbody tr:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, .03);
}
</style>
