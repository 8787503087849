<script setup lang="ts">
import { useGroupByPeriod } from '@/chart.helper';
import { chartColors, colors, currencyFormatter } from '@/const';
import { createTransactionTooltip } from '@/utils/chart.utils';
import { enableAnnotationTooltips } from '@/utils/higcharts.utils';
import { getGroupName } from '@/utils/instrument.utils';
import type { PropType } from 'vue';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import { ChartType } from '~/enums';
import type { PortfolioDtoType } from '~/portfolio.schema';

const { t } = useI18n();

const props = defineProps({
  values: {
    type: Array,
    required: true
  },
  annotations: {
    type: Array,
    required: false
  },
  isLoading: {
    type: Boolean,
    required: true
  },
  currencyCode: {
    type: String,
    required: false
  },
  round: {
    type: Boolean,
    required: false,
    default: true
  },
  label: {
    type: String,
    required: false
  },
  valueKey: {
    type: String,
    required: false,
    default: 'value'
  },
  portfolio: {
    type: Object as PropType<PortfolioDtoType>,
    required: false
  },
  chartType: {
    type: String,
    required: false,
    default: ChartType.Total
  }
});

const theme = useTheme();

function generateHighchartSeries(startDate, endDate, initialAmount, targetAmount, type = 'linear') {
    // Parse the input dates
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the number of days between start and end dates
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    // Calculate the daily growth rate
    let dailyRate = Math.pow(targetAmount / initialAmount, 1 / days);
    if (type === 'linear') {
      dailyRate = (targetAmount - initialAmount) / days / initialAmount + 1;
    }

    // Initialize the series data array
    const seriesData = [];

    // Generate the coordinates
    let currentAmount = initialAmount;
    let currentDate = new Date(start);
    for (let i = 0; i <= days; i++) {
        // Push the current date (X) and amount (Y) to the series data array
        seriesData.push([
            Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), // X value
            Math.round(currentAmount) // Y value
        ]);

        // Update the current amount for the next day
        if (type === 'linear')
          currentAmount += (targetAmount - initialAmount) / days;
        else
          currentAmount *= dailyRate;

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return seriesData;
}

const chartOptions = computed(() => {
  const valueKey =  [ChartType.CostbaseTotal, ChartType.CostbaseStacked].includes(props.chartType) ? 'costbase' : 'value';
  const isDark = theme.current.value.dark;
  const minValue = props.values.map(x => x).filter(x => x[valueKey] !== 0).sort((b, a) => b[valueKey] - a[valueKey])[0];
  const maxValue = props.values.map(x => x).sort((a, b) => b[valueKey] - a[valueKey])[0];
  const isSame = minValue?.[valueKey] === maxValue?.[valueKey];
  let seriesData = [] as any[];

  if (props.portfolio && props.portfolio.goal && props.portfolio.goal.enabled) {
    const goal = props.portfolio.goal;
    seriesData = generateHighchartSeries(goal.startDate, goal.targetDate, goal.startingValue, goal.targetValue);
  }

  const goalData = seriesData.filter((val) => {
    return val[0] >= new Date(props.values[0]?.date).getTime() && val[0] <= new Date(props.values[props.values.length - 1].date).getTime()
  })

  if (goalData.length > 0) {
    goalData[goalData.length - 1] = [new Date(props.values[props.values.length - 1].date).getTime(), goalData[goalData.length - 1][1]];
  }

  const groups = props.values[0]?.groupPerformance || [];
  const groupSeries = groups.map((group, i) => {
    return {
      type: 'area',
      name: getGroupName(group.name),
      color: colors[i],
      dashStyle: 'none',
      threshold: null,
      lineWidth: 2,
      stacking: 'normal',
      data: props.values.map(val => {
        return {
          x: new Date(val.date).getTime(),
          y: val.groupPerformance[i][valueKey] !== null ? (props.round ? Math.round(val.groupPerformance[i][valueKey]) : val.groupPerformance[i][valueKey]) : null,
        }
      }),
      marker: {
        enabled: false
      }
    }
  })

  const goalSeries = {
    name: t('label.goal'),
    data: goalData,
    color: colors[colors.length - 1],
    type: 'line',
    marker: {
      enabled: false
    },
    zIndex: 0,
    dashStyle: 'ShortDash',
    lineWidth: 2,
  }

  const mainSeries = {
    type: 'area',
    lineWidth: 3,
    name: props.label ?? t(`label.${(valueKey === 'value' ? 'networth' : 'costbase')}`),
    color: isDark ? 'rgb(83, 144, 217)' : '#0C4160',
    threshold: null,
    data: props.values.map(val => {
      const annotations = (props.annotations || [])
        .filter(a => ['buy', 'sell'].includes(a.type))
        .filter(a => a.x === new Date(val.date).getTime());
      const annotation = annotations.length > 0 ? annotations[0] : null;
      return {
        x: new Date(val.date).getTime(),
        y: val[valueKey] !== null ? (props.round ? Math.round(val[valueKey]) : val[valueKey]) : null,
        marker: {
          enabled: annotation ? true : undefined
        }
      }
    }),
    marker: {
      enabled: false
    }
  }

  let series: any[] = [];
  let categories = null;

  if ([ChartType.Total, ChartType.CostbaseTotal].includes(props.chartType)) {
    series.push(mainSeries);
    series.push(goalSeries);
  } else if ([ChartType.Stacked, ChartType.CostbaseStacked].includes(props.chartType)) {
    series = groupSeries;
  } else if (props.chartType === ChartType.Growth) {
    const tmp = ['capital', 'currency', 'income', 'fee'].map((key, i) => {
      return {
        type: 'column',
        name: t(`chart.label.${key}`),
        color: chartColors[i],
        dashStyle: 'none',
        lineWidth: 1,
        stacking: 'normal',
        data: props.values.map(val => {
          return {
            x: new Date(val.date).getTime(),
            y: val.valueChange[key] !== null ? (props.round ? Math.round(val.valueChange[key]) : val.valueChange[key]) : null,
          }
        }),
        marker: {
          enabled: false
        }
      }
    })
    const grouped = useGroupByPeriod(tmp);
    series = grouped.series;
    categories = grouped.categories;
  }
  

  return {
    chart: {
      type: 'area',
      height: 400,
      backgroundColor: 'transparent',
      events: {
        load: function() {
          enableAnnotationTooltips(this as any, isDark);

          // Find the last point in the series
          var series = this.series[1];
          var lastPoint = series?.data[series.data.length - 1];
          var big = false;

          if (!lastPoint?.y) {
            return;
          }

          // Enable the marker for the last point
          lastPoint?.update({
            marker: {
              enabled: true,
              radius: 5,
            }
          });
          
          let interval = setInterval(() => {
            if (!lastPoint?.series?.chart) {
              clearInterval(interval);
              return;
            }
            big = !big;
            lastPoint.update({
              marker: {
                enabled: true,
                radius: big ? 4 : 7
              }
            })
          }, 500)
        }
      }
    },
    exporting: {
      enabled: false
    },
    title:{
      text:''
    },
    xAxis: {
      type: categories ? 'category' : 'datetime',
      categories: categories || undefined,
      visible: true,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        }
      },
      minorTicks: false,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
      lineColor: isDark ? '#32323e' : '#0C4160',
    },
    legend:{
      enabled: series.length > 1,
      align: 'center',
      alignColumns: false,
      padding: 0,
      itemStyle: {
        color: isDark ? '#ffffff' : '#0C4160'
      }
    },
    time: {
      useUTC: true
    },
    annotations: [{
      draggable: '',
      visible: !isSame,
      zIndex: 5,
      labelOptions: {
        shape: 'callout',
        align: 'center',
        justify: true,
        overflow: 'none',
        crop: false,
        distance: 10,
        backgroundColor: theme.current.value.colors.success,
        borderWidth: 0,
        style: {
          fontSize: '14px',
          textOutline: '0px white',
          color: isDark ? '#ffffff' : '#FFFFFF',
        }
      },
      labels: [{
        point: {
            xAxis: 0,
            yAxis: 0,
            x: new Date(maxValue?.date).getTime(),
            y: maxValue?.[valueKey],
        },
        text: `<span class="balance-sm">${currencyFormatter(maxValue?.[valueKey], props.currencyCode, 0, 0)}</span>`,
        useHTML: true,
        crop: false,
        overflow: 'allow',
      }]
    }, {
      draggable: '',
      zIndex: 6,
      visible: !isSame,
      labelOptions: {
        shape: 'callout',
        align: 'center',
        justify: true,
        overflow: 'none',
        crop: false,
        distance: 10,
        borderWidth: 0,
        backgroundColor: theme.current.value.colors.error,
        style: {
          fontSize: '14px',
          textOutline: '0px white',
          color: isDark ? '#ffffff' : '#FFFFFF',
        }
      },
      labels: [{
        point: {
            xAxis: 0,
            yAxis: 0,
            x: new Date(minValue?.date).getTime(),
            y: minValue?.[valueKey],
        },
        text: `<span class="balance-sm">${currencyFormatter(minValue?.[valueKey], props.currencyCode, 0, 0)}</span>`,
        useHTML: true,
        crop: false,
        overflow: 'allow',
      }]
    }, {
      draggable: false,
      zIndex: -1,
      visible: true,
      useHTML: true,
      labelOptions: {
        shape: 'connector',
        align: 'center',
        justify: true,
        overflow: 'none',
        crop: false,
        allowOverlap: true,
        borderWidth: 0,
        style: {
          zIndex: 0,
          fontSize: '14px',
          textOutline: '0px white',
          color: isDark ? '#ffffff' : '#FFFFFF',
        }
      },
      labels: (props.annotations || []).map(a => ({
        point: function() {
          const xAxis = this?.series?.xAxis;
          const xMin = xAxis?.min || a.x;
          const xMax = (xAxis?.max || a.x);
          return {
            xAxis: 0,
            yAxis: 0,
            x: a.x < xMin ? xMin : (a.x > xMax ? xMax : a.x),
            y: this?.series?.yAxis?.min,
          }
        },
        text: `<div style="position:relative;z-index:0;opacity:1"><span style="background:white;" class="v-icon mdi ${a.icon} text-${a.color} rounded-circle pa-n1 v-icon--size-default"></span></div>`,
        useHTML: true,
        verticalAlign: 'top',
        distance: 0,
        crop: false,
        overflow: 'allow',
        tooltip: {
          text: createTransactionTooltip(a.data)
        }
      }))
    }],
    tooltip: {
      xDateFormat: '%A, %d %b %Y %H:%M',
      outside: true,
      useHTML: true,
      style: {
        zIndex: 99990
      },
      crosshairs: {
        color: isDark ? 'rgb(83, 144, 217)' : '#0C4160',
        dashStyle: 'solid',
        width: 2
      },
      shared: categories ? true : false,
      formatter: categories ? function() {
          var s = '<b>'+ this.x +'</b>',
              sum = 0;

          this.points.forEach((point) => {
            s += '<br/><span style="color:' + point.series.color + '">\u25CF</span> '+ point.series.name +': '+
                  (currencyFormatter(point.y, props.currencyCode));
              sum += point.y;
          });
          

          if (this.points.length > 1 && props.valueType === 'currency') {
            s += '<br/><br />Kokku: ' + currencyFormatter(sum, props.currencyCode);
          }
          
          return s;
      } : undefined,
    },
    yAxis: {
      visible: categories ? true : false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0.1,
      min: null,
      title: {
        text: null
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        },
        formatter: function() {
          return `<div class="balance">${currencyFormatter(this.value, props.currencyCode, 0, 0)}</div>`;
        },
        useHTML: true
      },
      plotLines: [{
        color: isDark ? '#32323e' : 'rgb(222, 231, 248)',
        width: 1,      // Thickness of the line
        value: 0,      // Position at zero
        zIndex: 0      // Ensures the line is on top of other elements
      }]
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0.1,
        borderWidth: 1,
        borderColor: isDark ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)',
      },
      series: {
        lineWidth: 3
      },
      area: {
        fillColor: props.chartType === 'total' ? {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgba(54, 153, 255, 0.5)'],
            [1, isDark ? 'rgba(54, 153, 255, 0)' : 'rgba(12, 65, 96, 0)']
        ]
        } : undefined,
        opacity: 0.8,
      }
    },
    series,
  }
})
</script>

<template>
  <highcharts :chartOptions="chartOptions" v-if="!isLoading" />
  <div v-if="!values.length || isLoading" class="mt-5">
    <v-skeleton-loader :boilerplate="!isLoading" height="180" style="width:100%;" type="ossein" />
  </div>
</template>

<style lang="scss">
.highcharts-tooltip-container {
  z-index: 999999 !important;
  position: relative;
}
</style>