import dayjs from "dayjs";
import Decimal from "decimal.js";
import { reactive, watch, type Ref, computed, ref } from "vue";
import type { AccountDtoType, CreateCashAccountTransactionDtoType } from "~/cash-account.schema";
import { InstrumentType, TransactionType } from "~/enums";
import { relatedAccount, relatedAccountUtils } from "./account.utils";
import { nr } from "@/const";
import { useInstrumentConfig } from "@/instrument.helper";
import { usePortfolioStore } from "@/stores/portfolioStore";
import { fetchWrapper } from "./helpers/fetch-wrapper";

export function useTransactionForm (
  account: Ref<AccountDtoType>,
  transaction: Ref<CreateCashAccountTransactionDtoType | undefined>,
  isIncomeForm?: Ref<boolean>) 
{
  const { iConf } = useInstrumentConfig(account)
  const portfolioStore = usePortfolioStore()
  const prediction = ref({
    relatedHoldingId: undefined,
  })

  const isIncome = computed(() => {
    return iConf.value?.allowedIncomeTypes.includes(form.transactionType) && ![TransactionType.Tax].includes(form.transactionType);
  })

  const showPrice = computed(() => {
    return iConf.value?.showPriceField(form.transactionType) && ![TransactionType.Split].includes(form.transactionType);
  })

  const showQuantity = computed(() => {
    return form.transactionType && iConf.value && iConf.value.showQuantityField(form.transactionType)
  })

  const getInitialDate = () => {
    let date;

    if (sessionStorage.getItem('transactionDate')) {
      date = sessionStorage.getItem('transactionDate');
    }

    if (date && dayjs(date).isValid()) {
      return date;
    }

    return dayjs().format('YYYY-MM-DD');
  }

  const form = reactive({
    date: getInitialDate(),
    instrumentId: undefined as any,
    transactionType: undefined as any,
    description: undefined as any,
    metadata: {
      costbase: undefined as any
    },
    account: {
      id: undefined as any,
      currencyCode: undefined as any,
      quantity: undefined as any,
      netAmount: undefined as any,
      grossAmount: undefined as any,
      fee: undefined as any,
      feeCurrencyCode: undefined as any,
      tax: undefined as any,
      taxCurrencyCode: undefined as any,
      price: undefined as any,
    },
    counterparty: {
      id: undefined as any,
      currencyCode: undefined as any,
      netAmount: undefined as any,
      grossAmount: undefined as any,
      fee: undefined as any,
      feeCurrencyCode: undefined as any,
      tax: undefined as any,
      taxCurrencyCode: undefined as any,
      price: undefined as any,
    }
  })

  function resetFormForNew () {
    form.description = undefined;
    form.metadata.costbase = undefined;
    form.account.quantity = undefined;
    form.account.netAmount = undefined;
    form.account.grossAmount = undefined;
    form.account.fee = undefined;
    form.account.tax = undefined;
    form.counterparty.netAmount = undefined;
    form.counterparty.grossAmount = undefined;
    form.counterparty.fee = undefined;
    form.counterparty.tax = undefined;
  }

  watch (() => account.value, (account) => {
    if (!account || !account.currencyCode) {
      return;
    }
    const id = account.instrument?.type === InstrumentType.TakenLoan ? account.instrument.id : account.id;

    form.account.id = id;
    form.account.currencyCode = account.currencyCode;
    form.account.feeCurrencyCode = account.currencyCode;
    form.account.taxCurrencyCode = account.currencyCode;
  }, { immediate: true })

  watch (() => transaction.value, (transaction) => {
    if (!transaction || !transaction.account) {
      return;
    }
    form.transactionType = transaction.transactionType;
    form.date = transaction.date;
    form.description = transaction.description;
    // @ts-expect-error
    form.account = {...transaction.account};
    // @ts-expect-error
    form.counterparty = transaction.counterparty;
    // @ts-expect-error
    form.metadata = transaction.metadata;
  }, { immediate: true })

  const calculatedGrossAmount = computed(() => {
    if (isIncome.value || form.transactionType === TransactionType.Conversion) {
      return nr(form.account.grossAmount);
    }

    let price = showPrice.value ? form.account.price : 1;

    if (!price || !form.account.quantity || [TransactionType.Split].includes(form.transactionType)) {
      return 0;
    }

    return new Decimal(price).mul(nr(form.account.quantity)).toDecimalPlaces(10).toNumber();
  })

  const calculatedNetAmount = computed(() => {
    const feeSign = [TransactionType.Sell, TransactionType.Withdraw].includes(form.transactionType) || isIncome.value ? -1 : 1;

    return new Decimal(form?.account?.grossAmount || 0)
      .add(nr(form.account.fee) * feeSign || 0)
      .add(nr(form.account.tax) * feeSign || 0)
      .toDecimalPlaces(10)
      .toNumber();
  })

  watch(calculatedNetAmount, (netAmount) => {
    form.account.netAmount = netAmount
  })

  watch(calculatedGrossAmount, (grossAmount) => {
    const hasPriceChanged = parseFloat(form.account.price || 0) !== transaction.value?.account?.price
    const hasQuantityChanged = parseFloat(form.account.quantity || 0) !== transaction.value?.account?.quantity
    const hasChanged = hasPriceChanged || hasQuantityChanged
    
    if (hasChanged && !isIncome.value) {
      form.account.grossAmount = grossAmount
    } else if (!hasChanged && transaction.value?.account?.grossAmount && !isIncome.value && form.transactionType !== TransactionType.Conversion) {
      form.account.grossAmount = transaction.value.account.grossAmount
    }
  })

  watch(() => form.date, (date) => {
    if (date && dayjs(date).isValid()) {
      sessionStorage.setItem('transactionDate', date);
    }
  })

  watch(iConf, (_iConf) => {
    if (_iConf && !form.transactionType && !isIncomeForm?.value) {
      form.transactionType = _iConf.allowedTransactionTypes[0];
    } else if (_iConf && isIncomeForm?.value) {
      form.transactionType = _iConf.allowedIncomeTypes[0];
    }
  })

  watch(() => [form.transactionType], async () => {
    const searchQuery = {
      transactionType: form.transactionType,
      holdingId: form.account.id,
      description: form.description,
      currency: form.account.currencyCode,
    }
    try {
      prediction.value = await fetchWrapper.post(`/api/v1/portfolio/${portfolioStore.activePortfolioId}/predict`, searchQuery) || {}
    } catch {
      prediction.value = {}
    }
  })

  const relatedHoldingId = computed(() => form.counterparty?.id)
  const relatedAccountOptions = relatedAccountUtils(account, transaction).relatedAccountOptions
  const relatedAccountModel = relatedAccount(relatedHoldingId)

  return {
    form,
    showPrice,
    showQuantity,
    isIncome,
    relatedAccountOptions,
    relatedAccountModel,
    calculatedNetAmount,
    calculatedGrossAmount,
    resetFormForNew,
    getInitialDate,
    prediction
  }
}
