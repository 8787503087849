import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

export class LoanConfigClass extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Loan;
  showQuantityInValue: boolean = false;
  quantityIsCurrency: boolean = true;
  icon: string = '📅';

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Writeoff,
    TransactionType.Opening,
    TransactionType.Fee,
  ]

  allowedIncomeTypes = [
    TransactionType.Interest,
    TransactionType.OtherIncome
  ]

  defaultPrice: number = 1
  hasPrice: boolean = false
  showQuantity: boolean = false
}

export default new LoanConfigClass();
