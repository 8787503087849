import { computed, ref, watch, type Ref } from "vue";
import { ChartType, PerformancePeriod, ReportGroupingType } from '~/enums';
import { useI18n } from "vue-i18n";
import { useAuthStore } from "./stores/authStore";
import { useRoute, useRouter } from "vue-router";
import dayjs from 'dayjs';
import { usePortfolioStore } from "./stores/portfolioStore";

const getDefaultRange = () => {
  let range

  try {
    range = JSON.parse(localStorage.getItem('defaultRange') || '');
  } catch (error) {
    range = null
  }

  if (!range) {
    range = {
      startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    }
  }

  return range
}

export function useGroupBy () {
  const { t } = useI18n();
  const portfolioStore = usePortfolioStore();

  const options = computed(() => {
    const builtInOptions = Object.values(ReportGroupingType).map((value) => {
      return {
        value,
        title: t(`filter.group_by.${value}`)
      }
    })

    const customOptions = (portfolioStore.categories || []).map((category) => {
      return {
        value: category.id,
        title: category.name
      }
    })

    customOptions.push({
      value: 'manage-custom',
      title: t('filter.group_by.manage_custom')
    })

    if (portfolioStore.selectedPortfolio?.canEdit) {
      return builtInOptions.concat(customOptions)
    } else {
      return builtInOptions
    }
  })

  return {
    groupByOptions: options
  }
}

export function usePerformanceFilter (flavour: 'past' | 'future' = 'past') {
  const authStore = useAuthStore();
  const portfolioStore = usePortfolioStore();

  const summaryPeriod = ref<PerformancePeriod>(authStore.getUserPreference(`summary.period.${flavour}`, PerformancePeriod.ThisMonth) as PerformancePeriod);
  const summaryRange = ref<{ startDate: string, endDate: string }>(authStore.getUserPreference(`summary.range.${flavour}`, getDefaultRange()));
  const summaryInterval = ref<string>(authStore.getUserPreference(`summary.interval.${flavour}`, 'month'));
  const summaryGroupBy = ref(authStore.getUserPreference(`summary.groupBy.${flavour}`, ReportGroupingType.AssetClass) as ReportGroupingType);
  const summaryChartType = ref(authStore.getUserPreference(`summary.chartType.${flavour}`, ChartType.Total) as ChartType);

  watch(() => portfolioStore.categories, (categories) => {
    const isCustomGrouping = typeof summaryGroupBy.value === 'number';
    const isCustomGroupingAvailable = Array.isArray(categories) && categories.length > 0;
    const isCustomGroupingSelected = (categories || []).find((category) => category.id === summaryGroupBy.value);

    if (isCustomGrouping && (isCustomGroupingAvailable && !isCustomGroupingSelected)) {
      summaryGroupBy.value = ReportGroupingType.AssetClass
    }
  }, { immediate: true });

  watch(() => [summaryPeriod, summaryRange, summaryInterval, summaryGroupBy, summaryChartType], async () => {
    authStore.setUserPreference(`summary.period.${flavour}`, summaryPeriod.value);
    authStore.setUserPreference(`summary.range.${flavour}`, summaryRange.value);
    authStore.setUserPreference(`summary.interval.${flavour}`, summaryInterval.value);
    authStore.setUserPreference(`summary.groupBy.${flavour}`, summaryGroupBy.value);
    authStore.setUserPreference(`summary.chartType.${flavour}`, summaryChartType.value);
  }, { deep: true });

  return {
    summaryPeriod,
    summaryRange,
    summaryInterval,
    summaryGroupBy,
    summaryChartType
  }
}

export function usePagination () {
  const authStore = useAuthStore();

  const page = ref(1);
  const perPage = ref(authStore.getUserPreference('pagination.perPage', 10));
  const perPageOptions = [
    {value: 10, title: '10'},
    {value: 25, title: '25'},
    {value: 50, title: '50'},
    {value: 100, title: '100'}
  ]

  watch(() => [perPage], async () => {
    authStore.setUserPreference('pagination.perPage', perPage.value);
  }, { deep: true });

  return {
    page,
    perPage,
    perPageOptions
  }
}

export function useFilters (params: {ref: Ref, key: string, type: 'int' | 'string' | 'boolean' | 'string[]' | 'int[]'}[], namespace: string = '', watchParams = false) {
  const currentRoute = useRoute()
  const router = useRouter()
  const booleanMap = {'true': true, 'false': false}

  const sync = () => {
    params.forEach((param) => {
      let value = currentRoute.query[`${namespace}${param.key}`]
      switch (param.type) {
        case 'boolean':
          param.ref.value = booleanMap[param.ref.value as 'true' | 'false'] || param.ref.value;
          break
        case 'int':
          param.ref.value = parseInt(value as string) || param.ref.value;
          break
        case 'string':
          param.ref.value = value || param.ref.value;
          break;
        case 'string[]':
          param.ref.value = (value as string)?.split(',').filter(x => !!x) || [];
          break;
        case 'int[]':
          param.ref.value = value ? value.toString().split(',').map(Number).filter(Number.isFinite) : param.ref.value;
          break;
      }
    })
  }

  const filters = computed(() => {
    return params.reduce<Record<string, any>>((acc, param) => {
      acc[`${namespace}${param.key}`] = param.ref.value || undefined
      return acc
    }, {})
  })

  const filtersForQuery = computed(() => {
    return params.reduce<Record<string, any>>((acc, param) => {
      let val = param.ref.value
      if (['int[]', 'string[]'].includes(param.type)) {
        val = param.ref.value.join(',')
      }
      acc[`${namespace}${param.key}`] = val
      return acc
    }, {})
  });

  watch(() => filtersForQuery, () => {
    if (watchParams) {
      updateRoute();
    }
  }, { deep: true })

  const updateRoute = () => {
    return router.replace({
      query: {
        ...router.currentRoute.value.query,
        ...filtersForQuery.value
      }
    })
  }

  return {
    filters,
    sync,
    updateRoute
  }
}