import { i18n } from '@/plugins/i18n';
import { computed, type ComputedRef, type Ref } from "vue"
import { instrumentConfig } from "~/config"
import { AssetClass, HoldingType, PaymentFrequency } from '~/enums';

const { t, te } = i18n.global

export const investmentTypeOptions = computed(() => {
  return Object.values(instrumentConfig).map((instrument) => {
    return {
      title: t(`instrumentType.${instrument.type}.title`),
      value: instrument.type
    }
  })
})

export const assetClassTypeOptions = computed(() => {
  return Object.values(AssetClass).map((assetClassKey) => {
    return {
      title: t(`asset_class.${assetClassKey}`),
      value: assetClassKey
    }
  })
})

export const paymentFrequencyOptions = computed(() => {
  return Object.values(PaymentFrequency).map((frequency) => {
    return {
      title: t(`payment_frequency.${frequency}`),
      value: frequency
    }
  })
})

export const useInvestment = (
  holding: ComputedRef<any>,
  code: ComputedRef<string | undefined>,
  logo: ComputedRef<string | undefined>
) => {
  const codeValue = computed(() => {
    if (code.value) {
      return code.value;
    } else if (![HoldingType.Loan, HoldingType.Cash].includes(holding.value?.type)) {
      return holding.value?.symbol;
    } else if (holding.value?.instrument?.displayName) {
      return holding.value?.instrument?.displayName;
    } else {
      return holding.value?.name;
    }
  });

  const logoValue = computed(() => {
    if (logo.value) {
      return logo.value;
    } else if ([HoldingType.Loan, HoldingType.Cash].includes(holding.value?.type)) {
      return holding.value?.logo;
    } else {
      return holding.value?.instrument?.logo;
    }
  });

  return {
    codeValue,
    logoValue
  }
}

export const getGroupName = (groupName: string) => {
  return te(`${groupName}`) ? t(`${groupName}`) : groupName.replace('custom.', '')
}
